function buildEquipmentSort(sort) {
  switch (sort) {
    case 'id':
      return 'id asc';
    case 'total_price':
      return 'total_price asc';
    default:
      return 'order_date desc';
  }
}

function updateEquipmentOrderList() {
  const sort = buildEquipmentSort(document.querySelector('#equipment_order_sort .li-check').id.split('-')[1]);
  const search = document.getElementById('search-equipment_order').value;
  const accountId = document.getElementById('equipment_orders').dataset.accountId;
  window.$.ajax({
    url: `/accounts/${accountId}/equipment_orders`,
    data: { sort, filter: { order_number_or_finance_contract_number_cont: search } },
    dataType: 'script',
    type: 'GET',
  });
  const pathUrl = window.changeDataFromUrl(window.location, { sort: `${sort}`, 'filter[order_number_or_finance_contract_number_cont]': search });
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.updateEquipmentOrderList = updateEquipmentOrderList;

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementById('equipment_orders-index');
  if (body) {
    const urlValues = new URL(window.location.href);
    const sorted = urlValues.searchParams.get('sort')?.split(' ')[0];
    if (sorted) {
      const entries = document.getElementById(`equipment_order_sort-${sorted}`);
      window.selectChosenOption(entries);
    }
    const filter = urlValues.searchParams.get('filter[order_number_or_finance_contract_number_cont]');
    if (filter) {
      document.getElementById('search-equipment_order').value = filter;
    }
    document.getElementById('search-equipment_order')?.addEventListener('keyup', window.debounce(() => {
      updateEquipmentOrderList();
    }, 500));
  }
});

function exportEquipmentOrders() {
  const sort = buildEquipmentSort(document.querySelector('#equipment_order_sort .li-check').id.split('-')[1]);
  const accountId = document.getElementById('equipment_orders').dataset.accountId;
  const exportType = document.querySelector('input[name="export_type"]:checked').value;
  const headers = new Headers({ 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content });
  const url = `/accounts/${accountId}/equipment_orders.xlsx?sort=${sort}&export_type=${exportType}`;
  document.getElementById('info-snackbar').classList.remove('tw-hidden');
  window.loadAnimationPending('#info-snackbar #loading-state');
  window.closeInformationModal('export-equipment_modal');
  fetch(url, {
    method: 'GET',
    headers,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then((blob) => {
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = 'Equipment Orders.xlsx';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      document.getElementById('info-snackbar').classList.add('tw-hidden');
      window.toggleSnackbar('success-snackbar', window.I18n.equipment_orders.index.export.success_snackbar);
    })
    .catch(() => {
      document.getElementById('info-snackbar').classList.add('tw-hidden');
      window.toggleSnackbar('warning-snackbar', window.I18n.generic_export_file_error);
    });
}
window.exportEquipmentOrders = exportEquipmentOrders;
