import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTControllersView = Backbone.View.extend({
  el: 'body',
  events: {
    'click #commandSelect': 'changeCommand',
    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #export': Shared.exportFile,
    'click .batch_command': 'batchCommandIssue',
    'click .new_batch_command': 'batchCommandRedirect',
    'click #executeCommandButton': 'executeBatchCommand',
    'click #whatever': 'toggle_checkboxes',
  },
  buildControllerColumns() {
    const columns = [
      {
        className: 'controller-checkbox text-center',
        orderable: false,
        data: null,
        name: null,
        defaultContent: '<input type="checkbox" class="form-input" export="" style="display: none;"/>',

        // Checkbox counter implementation
        // defaultContent: '<input type="checkbox" class="checkbox_counter" export="" style="display: none;"/>'
      },

      { data: 'site_id', name: 'site_id', visible: window.COLUMNS.site_id.visible },
      { data: 'site_name', name: 'site_name', visible: window.COLUMNS.site_name.visible },
      { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
      { data: 'link', name: 'name' },
      { data: 'site_full_address', name: 'site_full_address', visible: window.COLUMNS.site_full_address.visible },
      { data: 'site_address1', name: 'site_address1', visible: window.COLUMNS.site_address1.visible },
      { data: 'site_address2', name: 'site_address2', visible: window.COLUMNS.site_address2.visible },
      { data: 'site_city', name: 'site_city', visible: window.COLUMNS.site_city.visible },
      { data: 'site_state', name: 'site_state', visible: window.COLUMNS.site_state.visible },
      { data: 'site_country', name: 'site_country', visible: window.COLUMNS.site_country.visible },
      { data: 'site_postal_code', name: 'site_postal_code', visible: window.COLUMNS.site_postal_code.visible },
      { data: 'site_longitude', name: 'site_longitude', visible: window.COLUMNS.site_longitude.visible },
      { data: 'site_latitude', name: 'site_latitude', visible: window.COLUMNS.site_latitude.visible },
      { data: 'site_smartlink_type', name: 'site_smartlink_type', visible: window.COLUMNS.site_smartlink_type.visible },
      { data: 'run_status', name: 'run_status', visible: window.COLUMNS.run_status.visible },
      { data: 'mode', name: 'mode', visible: window.COLUMNS.mode.visible },
      { data: 'sensor_mode', name: 'sensor_mode', visible: window.COLUMNS.sensor_mode.visible },
      { data: 'sw_status', name: 'sw_status', visible: window.COLUMNS.sw_status.visible },
      {
        data: 'faults', name: 'faults', visible: window.COLUMNS.faults.visible, orderable: true,
      },
      { data: 'model', name: 'model', visible: window.COLUMNS.model.visible },
      { data: 'location', name: 'location', visible: window.COLUMNS.location.visible },
      { data: 'version', name: 'version', visible: window.COLUMNS.version.visible },
      { data: 'comm_enabled', name: 'comm_enabled', visible: window.COLUMNS.comm_enabled.visible },
      { data: 'device_time', name: 'device_time', visible: window.COLUMNS.device_time.visible },
      { data: 'num_zones', name: 'num_zones', visible: window.COLUMNS.num_zones.visible },
      {
        data: 'payment_status', name: 'payment_status', visible: window.COLUMNS.payment_status.visible, orderable: false,
      },
      { data: 'modem_version', name: 'modem_version', visible: window.COLUMNS.modem_version.visible },
      { data: 'bootloader_version', name: 'bootloader_version', visible: window.COLUMNS.bootloader_version.visible },
      { data: 'performs_overnight_update', name: 'performs_overnight_update', visible: window.COLUMNS.performs_overnight_update.visible },
      { data: 'aircard_iccid', name: 'aircard_iccid', visible: window.COLUMNS.aircard_iccid.visible },
      { data: 'subscription_id', name: 'subscription_id', visible: window.COLUMNS.subscription_id.visible },
      { data: 'subscription_name', name: 'subscription_name', visible: window.COLUMNS.subscription_name.visible },
      { data: 'subscription_end', name: 'subscription_end', visible: window.COLUMNS.subscription_end.visible },
      { data: 'flow_mode', name: 'flow_mode', visible: window.COLUMNS.flow_mode.visible },
      { data: 'review_requested', name: 'review_requested', visible: window.COLUMNS.review_requested.visible },
      { data: 'controller_type', name: 'controller_type', visible: window.COLUMNS.controller_type.visible },
      { data: 'virtual', name: 'virtual', visible: window.COLUMNS.virtual.visible },
      { data: 'controllers_created_at', name: 'controllers_created_at', visible: window.COLUMNS.controllers_created_at.visible },
    ];
    if (window.IS_ADMIN) {
      columns.push(
        { data: 'category', name: 'category', visible: window.COLUMNS.category.visible },
        {
          data: 'sales_territory', name: 'sales_territory', visible: window.COLUMNS.sales_territory.visible, orderable: false,
        },
        {
          data: 'sales_reps', name: 'sales_reps', visible: window.COLUMNS.sales_reps.visible, orderable: false,
        },
      );
    }
    columns.push(
      { data: 'aircard_hardware', name: 'aircard_hardware', visible: window.COLUMNS.aircard_hardware.visible },
      { data: 'modem_type_name', name: 'modem_type_name', visible: window.COLUMNS.modem_type_name.visible },
      {
        data: 'winterized', name: 'winterized', visible: window.COLUMNS.winterized.visible, orderable: true,
      },
      {
        data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
      },
    );
    if (window.COLUMNS.branch) {
      columns.splice(-1, 0, {
        data: 'branch', name: 'branch', visible: window.COLUMNS.branch.visible, orderable: true,
      });
    }
    return columns;
  },
  async initialize() {
    window.view = this;

    const onDatatableDraw = () => {
      window.renderFilterBoxes();
      Shared.fillBlankCells();
    };

    // Filter icon implementation in table for picking columns
    // Shared.show_filter_icon()

    // Checkbox counter implementation
    // Shared.show_checkbox_counter()

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[2, 'asc']],
      bAutoWidth: false,
      responsive: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],
      language: {
        emptyTable: '<div style="margin: 15px;">You don\'t have any controllers yet.',
        lengthMenu: 'Show _MENU_',
        search: '',
        paginate: {
          previous: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.1663L1.33333 6.49967L6 1.83301" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          next: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.83366L5.66667 6.50033L1 11.167" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        info: `${window.I18n.showing} <strong>_START_</strong> ${window.I18n.data_tables.to} <strong>_END_</strong> ${window.I18n.of} <strong>_TOTAL_</strong> ${window.I18n.results}`,
      },

      // Place the site id inside each <tr>
      fnRowCallback(nRow, aData, iDisplayIndex) {
        const row = $(nRow);
        if (aData.run_remote === true) {
          row.attr('id', aData.id).attr('n', iDisplayIndex);
          if (aData.global_commandable === true) {
            row.find('.controller-checkbox input').show();
          } else {
            row.find('.controller-checkbox input').remove();
          }
        } else {
          row.find('.controller-checkbox input').remove();
        }
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/controllers.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};
          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });
        },
      },
      stateLoadCallback() {
        const state = JSON.parse(localStorage.getItem(`DataTables_dataTable_${window.location.pathname}`));
        if (state) {
          state.order = [[4, 'asc']]; // Modify ordering on local storage
        }
        return state;
      },
      columns: this.buildControllerColumns(),
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').unbind();

    $('table#dataTable thead th:first').html('<input id="whatever" type="checkbox" class="form-input">');

    // Checkbox counter implementation
    // $('table#dataTable thead th:first').html('<input id="whatever" class="checkbox_counter" type="checkbox">')

    const regex = /[?&]q=([^&#]*)/g;
    const match = regex.exec(window.location.href);
    if (match?.[1]) {
      $('.dataTables_filter input').val(match[1]);
      Shared.search();
    }
  },
  // #########################################################################
  //
  //                            GLOBAL COMMANDS
  //
  // #########################################################################

  changeCommand() {
    const value = $('#commandIssue .dropdown-options .li-check').attr('id');
    $('#rainDelaySelector').toggle(value === 'rain_delay');
    if (value === 'take_snapshot') {
      $('#snapshotName').show({
        complete() {
          $('#snapshotNameValue').focus();
        },
      });
    } else {
      $('#snapshotName').hide();
    }
  },
  toggle_checkboxes(e) {
    const value = $(e.target).is(':checked');
    $('td input').prop('checked', value);
  },
  batchCommandIssue(e) {
    const controllers = this.getSelectedControllers();
    if (controllers.length === 0) {
      alert('You must select at least one controller');
      return false;
    }
    e.preventDefault();
    $('#commandIssue').modal({
      opacity: 65,
      overlayCss: { backgroundColor: '#000' },
      minWidth: 800,
      height: 500,
    });
    return true;
  },
  batchCommandRedirect(e) {
    e.preventDefault();
    const controllers = this.getSelectedControllers();
    if (controllers.length === 0) {
      alert('You must select at least one controller');
      return false;
    }
    const urlParams = controllers.map((ctl) => `ids[]=${ctl}`).join('&');
    window.location.href = `${e.target.href}?${urlParams}`;
    return true;
  },

  executeBatchCommand(e) {
    e.preventDefault();
    let instructionType = 2; // command
    const controllers = this.getSelectedControllers();
    const priority = $('#commandPriority').val();
    if (controllers.length === 0) {
      alert('You must select at least one controller');
      return false;
    }

    const action = $('#commandIssue .dropdown-options .li-check').attr('id');

    if (window.firstTimeAction === 0) {
      window.pushControllerSendCommandEvent('firstActionQuickSendCommand', controllers, action);
    }

    let payload = {};
    if (action === 'rain_delay') {
      const delayValue = parseInt($('#commandIssue .dropdown-options .li-check #rainDelayValue').val(), 10);
      payload = { action: 'rain_delay', delay: delayValue };
    } else if (action === 'standard_receive') {
      instructionType = 1; // receive
      payload = { action };
    } else if (action === 'take_snapshot') {
      instructionType = 9; // snapshot
      payload.name = $('#snapshotNameValue').val();
      $('#snapshotNameValue').val('');
    } else {
      payload = { action };
    }

    const batchRequest = {
      instruction_type: instructionType,
      payload,
      controllers,
      priority,
    };

    $('#commandIssue').modal('hide');
    $.ajax('/api/v2/batches', {
      type: 'POST',
      data: batchRequest,
      dataType: 'json',
      success: (data) => {
        document.querySelector('#control-alert-success a').href = `/global_commands/${data.result.quick_command.id}`;
        window.toggleSnackbar('control-alert-success', `${window.I18n.data_tables.extras.controllers.quick_command_sent} ${data.result.quick_command.name}`);
        window.historyWidgetView.showAndReload();
      },
      error: (data) => {
        const errorView = document.querySelector('#control-alert-error a');
        if (data.result?.quick_command?.id) {
          errorView.href = `/global_commands/${data.result.quick_command.id}`;
          errorView.classList.remove('tw-hidden');
        } else {
          errorView.classList.add('tw-hidden');
        }

        window.toggleSnackbar('control-alert-error');
      },
    });
    return true;
  },

  getSelectedControllers() {
    const controllers = [];
    document.querySelectorAll('.controller-checkbox input:checked').forEach((checkbox) => {
      const tr = checkbox.closest('tr');
      if (tr) {
        controllers.push(tr.id);
      }
    });
    return controllers.filter(Boolean);
  },
});
export default DTControllersView;
