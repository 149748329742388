import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTInspectionsView = Backbone.View.extend({
  el: 'body',
  events: {
    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #export': Shared.exportFile,
  },
  initialize() {
    window.view = this;

    const onDatatableDraw = () => {
      window.renderFilterBoxes();
      Shared.fillBlankCells();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[6, 'desc']],
      bAutoWidth: false,
      responsive: false,
      searching: true,
      language: {
        emptyTable: '<div style="margin: 15px;">You don\'t have any inspections yet',
        search: '',
        paginate: {
          previous: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.1663L1.33333 6.49967L6 1.83301" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          next: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.83366L5.66667 6.50033L1 11.167" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        info: `${window.I18n.showing} <strong>_START_</strong> ${window.I18n.data_tables.to} <strong>_END_</strong> ${window.I18n.of} <strong>_TOTAL_</strong> ${window.I18n.results}`,
      },
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, window.I18n.all]],
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',
      drawCallback: onDatatableDraw.bind(this),
      ajax: {
        url: '/api/v0/inspections.json',
        type: 'POST',
        error: Shared.errorPopup,
        data: (params) => {
          params.ransack = {};
          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });
        },
      },
      columns: [
        { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
        { data: 'title', name: 'title' },
        { data: 'controller_site_id', name: 'controller_site_id', visible: window.COLUMNS.controller_site_id.visible },
        { data: 'controller_site_name', name: 'controller_site_name', visible: window.COLUMNS.controller_site_name.visible },
        { data: 'controller_id', name: 'controller_id', visible: window.COLUMNS.controller_id.visible },
        { data: 'controller_name', name: 'controller_name', visible: window.COLUMNS.controller_name.visible },
        { data: 'date', name: 'date', visible: window.COLUMNS.date.visible },
        { data: 'user_name', name: 'user_name', visible: window.COLUMNS.user_name.visible },
        { data: 'controller_mode', name: 'controller_mode', visible: window.COLUMNS.controller_mode.visible },
        { data: 'controller_sw_status', name: 'controller_sw_status', visible: window.COLUMNS.controller_sw_status.visible },
        { data: 'controller_sensor_mode', name: 'controller_sensor_mode', visible: window.COLUMNS.controller_sensor_mode.visible },
        { data: 'controller_model', name: 'controller_model', visible: window.COLUMNS.controller_model.visible },
        { data: 'controller_location', name: 'controller_location', visible: window.COLUMNS.controller_location.visible },
        { data: 'controller_run_status', name: 'controller_run_status', visible: window.COLUMNS.controller_run_status.visible },
        { data: 'controller_version', name: 'controller_version', visible: window.COLUMNS.controller_version.visible },
        { data: 'controller_comm_enabled', name: 'controller_comm_enabled', visible: window.COLUMNS.controller_comm_enabled.visible },
        { data: 'controller_device_time', name: 'controller_device_time', visible: window.COLUMNS.controller_device_time.visible },
        { data: 'controller_num_zones', name: 'controller_num_zones', visible: window.COLUMNS.controller_num_zones.visible },
        { data: 'controller_modem_version', name: 'controller_modem_version', visible: window.COLUMNS.controller_modem_version.visible },
        { data: 'controller_bootloader_version', name: 'controller_bootloader_version', visible: window.COLUMNS.controller_bootloader_version.visible },
        { data: 'controller_performs_overnight_update', name: 'controller_performs_overnight_update', visible: window.COLUMNS.controller_performs_overnight_update.visible },
        { data: 'inspection_type', name: 'inspection_type', visible: window.COLUMNS.inspection_type.visible },
        { data: 'status', name: 'status', visible: window.COLUMNS.status.visible },
        { data: 'completed_at', name: 'completed_at', visible: window.COLUMNS.completed_at.visible },
        {
          data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
        },
      ],
    });

    $('.dataTables_filter input').unbind();
  },
});
export default DTInspectionsView;
