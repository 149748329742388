import SubscriptionDetailsTemplate from '@assets/javascripts/backbone/templates/subscriptions/subscription_details.ejs';
import SelectPlanModalTemplate from '@assets/javascripts/backbone/templates/subscriptions/select_plan_modal.ejs';
import ShoppingCartWidgetView from '@assets/javascripts/backbone/lib/include/shopping_cart.js';
import Backbone from 'backbone';
import bootbox from 'bootbox';
import moment from 'moment';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';

const DTSubscriptionsView = Backbone.View.extend({
  el: 'body',
  events: {
    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #export': Shared.exportFile,
    'click #dataTable tr': 'selectRow',
    'click .row-checkbox': 'boxWasChecked',
    'click #add-multiple-items': 'addMultipleToCart',
    'click .trig-add-to-cart': 'addToCart',
    'click .trig-plan-add-to-cart': 'addToCartWithPlan',
    'click #whatever': 'toggleCheckboxes',
    'keydown #plan-card-id': 'planCardVerifyTimer',
    'click #plan-card-button': 'activatePlanCard',
    'click #existing-sub-button': 'addToExistingSub',
    'click #trig-select-plan': 'selectPlanModal',
    'click #temporary-activation-button': 'temporaryActivation',
  },
  buildSubscriptionsColumns() {
    const columns = [
      {
        className: 'controller-checkbox',
        orderable: false,
        data: null,
        name: null,
        defaultContent: '<input type="checkbox" class="row-checkbox form-input" export="" style="display: none;"/>',
      },
      { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
      { data: 'name', name: 'name', visible: window.COLUMNS.name.visible },
      { data: 'controller_names', name: 'controller_names', orderable: false },
      {
        data: 'site_names', name: 'site_names', visible: window.COLUMNS.site_names.visible, orderable: false,
      },
      {
        data: 'active', name: 'active', visible: window.COLUMNS.active.visible, orderable: true,
      },
      {
        data: 'auto_recurring', name: 'auto_recurring', visible: window.COLUMNS.auto_recurring.visible, orderable: true,
      },
      {
        data: 'subscription_plans', name: 'subscription_plans', visible: window.COLUMNS.subscription_plans.visible, orderable: false,
      },
      {
        data: 'payment_method_type', name: 'payment_method_type', visible: window.COLUMNS.payment_method_type.visible, orderable: true,
      },
      {
        data: 'next_billing_date', name: 'next_billing_date', visible: window.COLUMNS.next_billing_date.visible, orderable: true,
      },
      {
        data: 'end_date', name: 'end_date', visible: window.COLUMNS.end_date.visible, orderable: true,
      },
      {
        data: 'controller_count', name: 'controller_count', visible: window.COLUMNS.controller_count.visible, orderable: true,
      },
      {
        data: 'shortcuts', name: 'shortcuts', visible: window.COLUMNS.shortcuts.visible, orderable: false,
      },
    ];
    if (window.COLUMNS.branch) {
      columns.splice(-1, 0, {
        data: 'branch', name: 'branch', visible: window.COLUMNS.branch.visible, orderable: false,
      });
    }
    return columns;
  },
  initialize() {
    window.view = this;
    this.subscriptionDetails = SubscriptionDetailsTemplate;
    this.selectPlanModal = SelectPlanModalTemplate;
    this.shoppingCart = new ShoppingCartWidgetView();
    $('#plan-card-id').mask('000-000-000');
    // modals have to be in the <body> space, outside scope
    $('body').append(this.selectPlanModal());
    $('#trig-add-to-cart').on('click', this.addToCart); // then bind it's buttons
    $('.trig-free-tier-activation').on('click', this.freeTierActivation); // then bind it's buttons

    const onDatatableDraw = () => {
      window.renderFilterBoxes();
      Shared.fillBlankCells();
      // if we only have one row (that isn't the 'no records found' row), auto-expand it's details
      if ($('#dataTable tbody').find('tr').length === 1) {
        const tr = $($('#dataTable tbody').find('tr')[0]);
        if (tr.find('td.dataTables_empty').length === 0) { // exclude the 'no records found' row
          this.expandSiteDetails(null, tr[0]);
        }
      }
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[5, 'asc']],
      bAutoWidth: false,
      responsive: false,
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // Place the site id inside each <tr>
      fnRowCallback(nRow, aData, iDisplayIndex) {
        const row = $(nRow);
        if ((aData.allows_multiple_controllers === false) && (aData.allows_add_to_cart === true) && (aData.can_edit === true)) {
          row.attr('id', aData.id).attr('n', iDisplayIndex);
          row.find('.controller-checkbox input').show();
        } else {
          row.find('.controller-checkbox input').remove();
        }
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/subscriptions.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};

          if (window.getParameterByName('upcoming') === 'true') {
            const testdate = moment().add(30, 'days').format('YYYY-MM-DD');
            params.ransack.ends_date_lteq = testdate;
            $('#ends_date_lteq').val(testdate);
            params.order = { 0: { column: '8', dir: 'desc' } };
          } else if (window.getParameterByName('clear') !== 'true') {
            const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
            Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));
          }

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });

          // filter out cancelled (status 9) controllers unless specifically queried
          if ((params.ransack.payment_method_type_eq !== '9') && (window.getParameterByName('clear') !== 'true')) {
            params.ransack.payment_method_type_not_eq = 9;
          }
        },
      },

      columns: this.buildSubscriptionsColumns(),
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').unbind();

    $('thead th:first').html('<input id="whatever" type="checkbox" class="form-input">');

    const regex = /[?&]q=([^&#]*)/g;
    const match = regex.exec(window.location.href);
    if (match?.[1]) {
      $('.dataTables_filter input').val(match[1]);
      Shared.search();
    }
  },
  selectRow(event) {
    if (!event.target.classList.contains('no_click')) {
      const tr = event.target.closest('tr');
      if (!tr) return;

      const row = window.table.row(tr);
      if (row.data() && (row.data().allows_add_to_cart === true || row.data().allows_activate_plan === true)) {
        this.expandSiteDetails(event, tr);

        if (tr.classList.contains('selected')) {
          $('#dataTable tr').removeClass('selected');
        }
        $('#dataTable tr').removeClass('selected');
        tr.classList.add('selected');
      }
    }
  },
  expandSiteDetails(e, tr = null) {
    // don't expand subtable if this is the Disconnect Aircard button
    if ($(e?.target)?.attr('id') === 'disconnect-aircard-button') {
      return;
    }
    const self = this;
    if (!tr) tr = e.target.closest('tr');
    if (tr.classList.contains('no_click')) return;

    const row = window.table.row(tr);
    if (row.child.isShown()) {
    // This row is already open - close it
      row.child.hide();
      tr.classList.remove('shown');
    } else {
    // Close all rows
      $('#dataTable tr').each((i, el) => {
        $(el).removeClass('shown');
        window.table.row(el).child.hide();
      });

      // Open this row
      row.child(self.formatSiteDetails(row.data())).show();
      tr.classList.add('shown');
    }
  },
  selectPlanModal(e) {
    const target = $(e.currentTarget);
    const freeActivatePath = target.data('free-activate-path');
    const freeRedirectPath = target.data('free-redirect-path');
    const subId = target.data('subscription-id');

    $('#select-plan-modal').data('free-activate-path', freeActivatePath);
    $('#select-plan-modal').data('free-redirect-path', freeRedirectPath);
    $('#select-plan-modal').data('subscription-id', subId);

    $('#select-plan-modal').modal('show');
  },
  temporaryActivation(e) {
    const target = $(e.currentTarget);
    target.prop('disabled', true);
    target.text('Activating...');

    $.ajax({
      type: 'POST',
      url: target.data('activate-path'),
      success: (data) => {
        if (data.success === true) {
          window.location = target.data('redirect-path');
        } else {
          bootbox.alert('Temporary activation failed! Please try again or contact support');
          target.prop('disabled', false);
          target.text('Activate Temporarily');
        }
      },
    });
  },
  freeTierActivation(e) {
    const target = $('#select-plan-modal');
    e.preventDefault();
    target.prop('disabled', true);
    target.text('Activating...');

    $.ajax({
      type: 'POST',
      url: target.data('free-activate-path'),
      success: (data) => {
        if (data.success === true) {
          window.location = target.data('free-redirect-path');
        } else {
          bootbox.alert('Free tier activation failed! Please try again or contact support');
          target.prop('disabled', false);
          target.text('Free Tier');
        }
      },
    });
  },
  planCardVerifyTimer() {
    clearTimeout(window.timer);
    window.timer = setTimeout(this.checkPlanCard.bind(this), 500);
  },
  activatePlanCard(e) {
    const target = $(e.currentTarget);
    const planCardId = $('#plan-card-id').val().replace(/\D/g, '');
    target.prop('disabled', true);
    target.text('Activating...');
    $.ajax({
      type: 'POST',
      data: { plan_card: planCardId },
      url: target.data('activate-path'),
      success: (data) => {
        if (data.success === true) {
          window.location = target.data('redirect-path');
        } else {
          bootbox.alert('Plan card could not activate! Please try again or contact support');
          target.prop('disabled', false);
          target.text('Activate with Plan Card');
        }
      },
    });
  },
  addToExistingSub(e) {
    const target = $(e.currentTarget);
    const subId = $('#existing-sub').val();
    target.prop('disabled', true);
    target.text('Attaching...');
    $.ajax({
      type: 'POST',
      data: { target_subscription_id: subId },
      url: target.data('transfer-to-subscription-path'),
      success: (data) => {
        if (data.success === true) {
          window.location = target.data('redirect-path');
        } else {
          const errorMessage = `Could not add to subscription! Please try again or contact support. ${data.reason}`;
          bootbox.alert(errorMessage);
          target.prop('disabled', false);
          target.text('Attach to Subscription');
        }
      },
    });
  },
  checkPlanCard() {
    const planCardId = $('#plan-card-id').val().replace(/\D/g, '');
    if (planCardId.length === 9) {
      $.ajax({
        type: 'POST',
        data: { plan_card: planCardId },
        url: '/api/v2/subscriptions/validate_plan_card',
        success: (data) => {
          if (data.valid === true) {
            $('#plan-card-form-group').removeClass('has-error');
            $('.plan-card-error').hide();
            $('.plan-card-success').show();
            $('#plan-card-form-group').addClass('has-feedback');
            $('#plan-card-button').prop('disabled', false);
            $('#plan-card-form-group').addClass('has-success');
          } else {
            $('#plan-card-button').prop('disabled', true);
            $('#plan-card-form-group').addClass('has-feedback');
            $('#plan-card-error-message').text(data.reason);
            $('.plan-card-error').show();
            $('.plan-card-success').hide();
            $('#plan-card-form-group').addClass('has-error');
          }
        },
        error: (jqXHR) => {
          const returnObj = JSON.parse(jqXHR.responseText);
          $('#plan-card-button').prop('disabled', true);
          $('#plan-card-error-message').show();
          $('#plan-card-error-message').text(returnObj.reason);
        },
      });
    } else {
      $('#plan-card-button').prop('disabled', true);
    }
  },
  formatSiteDetails(s) {
    return this.subscriptionDetails({ row: s });
  },
  addToCartWithPlan() {
    const subId = $('#select-plan-modal').data('subscription-id');
    this.shoppingCart.addToCart(subId);
    $('#select-plan-modal').modal('hide');
  },
  addToCart(e) {
    const ctlId = $(e.currentTarget).closest('tr').attr('id');
    this.shoppingCart.addToCart(ctlId, true);
  },
  addMultipleToCart(e) {
    e.preventDefault();
    const self = this;
    document.querySelectorAll('.row-checkbox:checked').forEach((row) => {
      const ctlId = row.closest('tr').id;
      self.shoppingCart.addToCart(ctlId, true);
    });
  },
  toggleCheckboxes(e) {
    const value = $(e.target).is(':checked');
    $('td input').prop('checked', value);
    this.boxWasChecked();
  },
  boxWasChecked() {
    if ($('.row-checkbox:checked').length > 0) {
      $('#add-multiple-items').removeClass('tw-hidden');
    } else {
      $('#add-multiple-items').addClass('tw-hidden');
    }
  },
  getSelectedControllers() {
    const controllers = [];
    $('.controller-checkbox input:checked').each((_e, element) => controllers.push($(element).closest('tr').attr('id')));
    return controllers;
  },
});
export default DTSubscriptionsView;
