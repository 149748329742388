import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';
import '@assets/javascripts/external/utilities.js';

const DTProposalsView = Backbone.View.extend({
  el: 'body',
  events: {
    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #export': Shared.exportFile,
  },
  buildProposalColumns() {
    const proposalColumns = [
      {
        className: 'proposal-checkbox',
        orderable: false,
        data: null,
        name: null,
        defaultContent: '<input type="checkbox" class="form-input" export="" style="display: none;"/>',
      },
      { data: 'id', name: 'id', visible: window.COLUMNS.id.visible },
      {
        data: 'site_name', name: 'site_name', visible: window.COLUMNS.site_name.visible, orderable: false,
      },
      {
        data: 'controller_names', name: 'controller_names', visible: window.COLUMNS.controller_names.visible, orderable: false,
      },
      {
        data: 'inspection_titles', name: 'inspection_titles', visible: window.COLUMNS.inspection_titles.visible, orderable: false,
      },
      { data: 'price_list', name: 'price_list', visible: window.COLUMNS.price_list.visible },
      {
        data: 'emergency', name: 'emergency', visible: window.COLUMNS.price_list.visible, orderable: false,
      },
      {
        data: 'notes', name: 'notes', visible: window.COLUMNS.notes.visible, orderable: false,
      },
      { data: 'status', name: 'status', visible: window.COLUMNS.status.visible },
      { data: 'sent_by', name: 'sent_by', visible: window.COLUMNS.sent_by.visible },
      { data: 'responder', name: 'responder', visible: window.COLUMNS.responder.visible },
      { data: 'response_notes', name: 'response_notes', visible: window.COLUMNS.response_notes.visible },
      { data: 'completed_by', name: 'completed_by', visible: window.COLUMNS.completed_by.visible },
      { data: 'created_at', name: 'created_at', visible: window.COLUMNS.created_at.visible },
      { data: 'last_sent', name: 'last_sent', visible: window.COLUMNS.last_sent.visible },
      { data: 'responded_at', name: 'responded_at', visible: window.COLUMNS.responded_at.visible },
      { data: 'completed_at', name: 'completed_at', visible: window.COLUMNS.completed_at.visible },
      { data: 'grand_total', name: 'grand_total', visible: window.COLUMNS.grand_total.visible },
      { data: 'approved_total', name: 'approved_total', visible: window.COLUMNS.approved_total.visible },
      {
        data: 'actions', name: 'actions', visible: window.COLUMNS.actions.visible, orderable: false,
      },
    ];

    if (window.USE_PROPOSAL_PRIORITY) {
      proposalColumns.splice(
        17,
        0,
        { data: 'noncritical_total', name: 'noncritical_total', visible: window.COLUMNS.noncritical_total.visible },
        { data: 'critical_total', name: 'critical_total', visible: window.COLUMNS.critical_total.visible },
      );
    }

    if (window.COLUMNS.branch) {
      proposalColumns.splice(-1, 0, {
        data: 'branch', name: 'branch', visible: window.COLUMNS.branch.visible, orderable: false,
      });
    }
    return proposalColumns;
  },
  initialize() {
    console.log('Proposals DT initialized');
    console.log(`company use priority for proposals is ${window.USE_PROPOSAL_PRIORITY}`);
    window.view = this;

    const onDatatableDraw = () => {
      Shared.fillBlankCells();
      window.renderFilterBoxes();
      document.querySelectorAll('[data-tooltip-target]').forEach((triggerEl) => {
        const targetEl = document.getElementById(triggerEl.getAttribute('data-tooltip-target'));
        const triggerType = triggerEl.getAttribute('data-tooltip-trigger');
        const placement = triggerEl.getAttribute('data-tooltip-placement');
        /* eslint-disable-next-line no-new */
        new window.Tooltip(targetEl, triggerEl, {
          placement: placement || 'bottom',
          triggerType: triggerType || 'hover',
        });
      });

      document.querySelectorAll('.controller_expanded_name, .inspection_expanded_name').forEach((element) => {
        element.parentElement.classList?.add('tw-cursor-pointer', 'hover:tw-bg-blue-100');
        element.parentElement.setAttribute('onclick', "this.children[0].classList.remove('tw-rows-ellipsis')");
      });
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      order: [[1, 'desc']],
      bAutoWidth: false,
      responsive: false,
      searching: true,
      iDisplayLength: 50,
      language: {
        emptyTable: '<div style="margin: 15px;">You don\'t have any proposals yet',
        search: '',
        paginate: {
          previous: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.1663L1.33333 6.49967L6 1.83301" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          next: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.83366L5.66667 6.50033L1 11.167" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        info: `${window.I18n.showing} <strong>_START_</strong> ${window.I18n.data_tables.to} <strong>_END_</strong> ${window.I18n.of} <strong>_TOTAL_</strong> ${window.I18n.results}`,
      },
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, 'All']],

      // place the proposal id inside each <tr>
      fnRowCallback(nRow, aData, iDisplayIndex) {
        const row = $(nRow);
        if (aData.can_be_combined === true) {
          row.attr('id', aData.id).attr('n', iDisplayIndex);
          row.find('.proposal-checkbox input').show();
        }
      },

      // The layout of the dataTables
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',

      drawCallback: onDatatableDraw.bind(this),

      ajax: {
        url: '/api/v0/proposals.json',
        type: 'POST',
        error: Shared.errorPopup,
        data(params) {
          params.ransack = {};

          if (window.getParameterByName('archived') === 'false') {
            document.getElementById('include_archived_eq').checked = false;
          } else if (window.getParameterByName('clear') !== 'true') {
            const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
            Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));
          }

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });
        },
      },

      columns: this.buildProposalColumns(),
    });

    // Unbind the dataTables default behavior to search
    // apon keyup or whatever it does?
    $('.dataTables_filter input').off();
  },
});
export default DTProposalsView;
