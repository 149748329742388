import Backbone from 'backbone';
import $ from 'jquery';
import * as Shared from '@assets/javascripts/backbone/lib/include/data_tables.js';
import ShoppingCartWidgetView from '@assets/javascripts/backbone/lib/include/shopping_cart.js';
import SubscriptionDetailsTemplate from '@assets/javascripts/backbone/templates/accounts/subscription_details.ejs';

const DTAccountSubscriptionsView = Backbone.View.extend({
  el: 'body',
  events: {
    'keydown .dataTables_filter input': Shared.delaySearch,
    'click #dataTable tr.odd, #dataTable tr.even, #dataTable .subtable .subtable-tr': 'selectRow',
    'click #export': Shared.exportFile,
    'click #customized-show-dropdown .show-option': 'updateTableByShowOption',
    'click #add-to-cart': 'addToCart',
    'keydown #activate-plan-card-modal input': 'planCardVerifyTimer',
    'click #activate-plan-card-modal button.activate': 'activatePlanCard',
  },
  buildSubscriptionsColumns() {
    const columns = [
      { data: 'more', name: 'more', visible: true },
      { data: 'link', name: 'sites.name', visible: true },
      { data: 'full_address', name: 'full_address', visible: true },
      {
        data: 'controllers_count', name: 'controllers_count', visible: true, orderable: false,
      },
      {
        data: 'active_subscriptions', name: 'active_subscriptions', visible: true, orderable: false,
      },
    ];
    if (window.COLUMNS.branch) {
      columns.push({
        data: 'branch', name: 'branch', visible: true, orderable: false,
      });
    }
    return columns;
  },
  initialize() {
    this.shoppingCart = new ShoppingCartWidgetView();
    this.subscriptionDetails = SubscriptionDetailsTemplate;
    window.view = this;
    $('#activate-plan-card-modal input').mask('000-000-000');

    const onDatatableDraw = () => {
      window.renderFilterBoxes();
      Shared.fillBlankCells();
    };

    window.table = $('#dataTable').DataTable({
      processing: true,
      serverSide: true,
      bStateSave: true,
      responsive: false,
      order: [[1, 'asc']],
      bAutoWidth: false,
      language: {
        emptyTable: `<div style="margin: 15px;">${window.I18n.accounts.subscriptions.empty}</div>`,
        lengthMenu: 'Show _MENU_',
        search: '',
        paginate: {
          previous: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.1663L1.33333 6.49967L6 1.83301" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          next: '<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.83366L5.66667 6.50033L1 11.167" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        info: `${window.I18n.showing} <strong>_START_</strong> ${window.I18n.data_tables.to} <strong>_END_</strong> ${window.I18n.of} <strong>_TOTAL_</strong> ${window.I18n.results}`,
      },
      iDisplayLength: 50,
      aLengthMenu: [[5, 10, 15, 25, 50, 100, 500, 1000, 100000], [5, 10, 15, 25, 50, 100, 500, 1000, window.I18n.all]],
      fnRowCallback(nRow, aData) {
        const row = $(nRow);
        aData.controllers.forEach((c, i) => {
          if (!c.allows_multiple_controllers && c.allows_add_to_cart && c.can_edit) {
            row.attr(`controller-${i}-input`, true);
          } else {
            row.attr(`controller-${i}-input`, false);
          }
        });
      },
      dom: '<"dt_tools"fl><"dt_filterbar"><"dt_table"t><"dt_footer"ip>',
      drawCallback: onDatatableDraw.bind(this),
      ajax: {
        url: '/api/v0/accounts/subscriptions',
        type: 'POST',
        data(params) {
          params.ransack = {};
          params.account_id = window.location.pathname.split('/')[2];
          const savedFilters = JSON.parse(localStorage.getItem(`local-${$('#saved').data('filters')}`)) || {};
          Object.entries(savedFilters).forEach(([key, value]) => window.setRansackValue(key, value));
          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          Object.entries(Shared.extractRansackFromUrl()).forEach(([key, val]) => {
            params.ransack[key] = val;
            window.setRansackValue(key, val);
          });

          document.querySelectorAll('.ransack_v2').forEach((element) => {
            const value = window.getRansackInputValue(element);
            if (value) {
              params.ransack[element.id] = value;
            }
          });

          params.show = document.getElementById('dt-show-menu-select')?.dataset?.value || (new URLSearchParams(window.location.search)).get('show');
        },
        error: Shared.errorPopup,
      },
      columns: this.buildSubscriptionsColumns(),
    });

    window.table.on('xhr', () => {
      const json = window.table.ajax.json();
      $('#account_subscriptions-index').data('admin', json.is_admin);
    });

    $('.dataTables_filter input').unbind();

    const regex = /[?&]q=([^&#]*)/g;
    const match = regex.exec(window.location.href);
    if (match?.[1]) {
      $('.dataTables_filter input').val(match[1]);
      Shared.search();
    }

    window.addEventListener('load', () => {
      const showContainer = document.createElement('div');
      showContainer.id = 'customized-show-dropdown';
      showContainer.innerHTML = `<p class="tw-hidden lg:tw-block">${window.I18n.accounts.subscriptions.show}</p> \
                                  <div id="dt-show-menu" onclick="document.getElementById('dt-show-menu-options').classList.toggle('tw-hidden')"> \
                                    <div id="dt-show-menu-select"> \
                                      <p>All</p> \
                                      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"> \
                                        <path d="M1.33337 3.99967L4.00004 1.33301L6.66671 3.99967M6.66671 7.99967L4.00004 10.6663L1.33337 7.99967" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> \
                                      </svg> \
                                    </div> \
                                  </div>`;
      const showOptionsContainer = $('<div id="dt-show-menu-options" class="tw-hidden"></div>');
      const showOptions = [['all', 'expired', 'expiring-soon'], [window.I18n.accounts.subscriptions.all, window.I18n.accounts.subscriptions.expired, window.I18n.accounts.subscriptions.expiring_soon]];
      for (let i = 0; i < showOptions[0].length; i += 1) {
        showOptionsContainer.append(`<div class="show-option" data-value="${showOptions[0][i]}" onclick="updateAccountSubscriptionsUrlWithShowOption(this)">${showOptions[1][i]}</div>`);
      }
      document.querySelector('#dt-filters-container button').after(showContainer);
      $('#dt-show-menu').append(showOptionsContainer);
      $('#dt-show-menu-options div').on('click', (e) => $('#dt-show-menu-select p').text(e.currentTarget.textContent));
      const showInput = (new URLSearchParams(window.location.search)).get('show');
      if (showInput) {
        $('#dt-show-menu-select p').text(document.querySelector(`[data-value="${showInput}"]`).textContent);
      }
    });

    $(document).mouseup((event) => {
      const dropdown = $('#dt-length-menu-options, #dt-show-menu-options');
      if (!dropdown.is(event.target) && (dropdown.has(event.target).length <= 0)) {
        dropdown.addClass('tw-hidden');
      }
    });
  },
  updateTableByShowOption(event) {
    document.getElementById('dt-show-menu-select').dataset.value = event.target.dataset.value;
    window.table.ajax.reload();
  },

  // --------
  // SUBTABLE
  // --------

  selectRow(event) {
    if ($(event.target).closest('thead').length > 0) return; // don't do anything if this is the header
    if ($(event.target).closest('td').hasClass('dataTables_empty')) return; // don't do anything if this is the 'empty table' message
    if ($(event.target).closest('th, td').hasClass('subscription-checkbox')) return; // don't do anything if this is the select subscription checkbox
    if ($(event.target).closest('td').hasClass('shortcuts-col').length > 0) return; // # don't collapse if this is the Actions section
    if ($(event.target).closest('#manage-subscription-btn').length > 0) return; // don't collapse if this is the Manage button

    const tr = event.target.closest('tr');

    // Check if subtable was clicked
    if (tr.classList.contains('subtable-tr')) {
      const parentRow = tr.closest('table').closest('tr').previousElementSibling;
      this.close_subtable(parentRow);
    } else {
      if (tr.classList.contains('previously-shown')) {
        if (tr.nextElementSibling) {
          tr.nextElementSibling.classList.remove('tw-hidden');
        }
      } else {
      // Show subscription detail subtable for the first time
        const row = window.table.row(tr);
        row.child(this.subscriptionDetails({
          site: row.data(),
          is_admin: document.getElementById('account_subscriptions-index').dataset.admin,
        })).show();
        tr.classList.add('previously-shown');
        this.removeNotAllowedAndDisableInputs(tr);
        window.updateSelectedColumns();
      }

      // Check if the clicked row is already selected
      if (tr.classList.contains('selected')) {
      // Close the selected subtable
        Shared.toggleChevronIcon(tr);
        tr.classList.remove('selected');
        if (tr.nextElementSibling) {
          tr.nextElementSibling.classList.add('tw-hidden');
        }
      } else {
      // Close all other subtables
        document.querySelectorAll('#dataTable tr.selected').forEach((tableTr) => {
          this.close_subtable(tableTr);
        });

        // Expand subtable
        Shared.toggleChevronIcon(tr);
        tr.classList.add('selected');
        if (tr.nextElementSibling) {
          tr.nextElementSibling.classList.remove('tw-hidden');
        }
      }
    }
  },
  close_subtable(tr) {
    Shared.toggleChevronIcon(tr);
    tr.classList.remove('selected');
    if (tr.nextElementSibling) {
      tr.nextElementSibling.classList.add('tw-hidden');
    }
  },
  removeNotAllowedAndDisableInputs(tr) {
    const controllerIds = document.getElementById('account_subscriptions-table').dataset.controllerIds.split(',').map(Number);
    const subtable = tr.nextElementSibling;

    subtable.querySelectorAll('[id^="select-from"]').forEach((input, i) => {
      if (tr.getAttribute(`controller-${i}-input`) === 'false') {
        input.remove();
      }
      if (controllerIds.includes(parseInt(input.dataset.controllerId, 10))) {
        input.disabled = true;
        input.checked = true;
      }
    });

    // Remove general input of each subtable if there is no input allowed inside
    if (Array.from(subtable.querySelectorAll('[id^="select-from"]:not(:disabled)')).length === 0) {
      subtable.querySelectorAll('[id^="select-all"]').forEach((selectAll) => selectAll.remove());
    }
  },
  addToCart() {
    $('[id^="select-from-"]:checked:not(:disabled)').each((_, input) => {
      const ctlId = input.id.split('-')[4];
      this.shoppingCart.addToCart(ctlId);
    });
  },
  // ---------
  // PLAN CARD
  // ---------
  planCardVerifyTimer() {
    clearTimeout(window.timer);
    window.timer = setTimeout(this.checkPlanCard, 500);
  },
  async checkPlanCard() {
    const input = $('#activate-plan-card-modal input');
    const activateButton = $('#activate-plan-card-modal button.activate');
    const planCardId = input.val().replace(/\D/g, '');

    if (planCardId.length === 9) {
      try {
        const data = await $.ajax({
          type: 'POST',
          data: { plan_card: planCardId },
          url: '/api/v2/subscriptions/validate_plan_card',
        });

        if (data.valid === true) {
          $('#activate-plan-card-modal .not-valid').addClass('tw-hidden');
          $('#activate-plan-card-modal .valid').removeClass('tw-hidden');
          input.removeClass('tw-border-red-500 tw-border-gray-300');
          input.addClass('tw-border-green-700');
          input.blur();
          activateButton.addClass('tw-btn-action-blue');
          activateButton.removeClass('tw-btn-disabled');
        } else {
          $('#activate-plan-card-modal .valid').addClass('tw-hidden');
          $('#activate-plan-card-modal .not-valid').removeClass('tw-hidden');
          $('#activate-plan-card-modal .not-valid p').text(data.reason);
          input.removeClass('tw-border-green-700 tw-border-gray-300');
          input.addClass('tw-border-red-500');
          input.blur();
          activateButton.removeClass('tw-btn-action-blue');
          activateButton.addClass('tw-btn-disabled');
        }
      } catch {
        activateButton.removeClass('tw-btn-action-blue');
        activateButton.addClass('tw-btn-disabled');
      }
    } else {
      $('#activate-plan-card-modal .not-valid').addClass('tw-hidden');
      $('#activate-plan-card-modal .valid').addClass('tw-hidden');
      activateButton.removeClass('tw-btn-action-blue');
      activateButton.addClass('tw-btn-disabled');
      input.removeClass('tw-border-green-700 tw-border-red-500');
      input.addClass('tw-border-gray-300');
    }
  },
  async activatePlanCard(e) {
    const planCardId = $('#activate-plan-card-modal input').val().replace(/\D/g, '');
    const subId = e.currentTarget.dataset.subId;

    try {
      const data = await $.ajax({
        type: 'POST',
        data: { plan_card: planCardId },
        url: `/api/v2/subscriptions/${subId}/activate_plan_card`,
      });

      $('#activate-plan-card-modal').addClass('tw-hidden');

      if (data.success === true) {
        $('#warning-snackbar').addClass('tw-hidden');
        window.toggleSnackbar('success-snackbar', data.reason);
      } else {
        $('#success-snackbar').addClass('tw-hidden');
        window.toggleSnackbar('warning-snackbar', data.reason);
      }
    } catch {
      $('#activate-plan-card-modal').addClass('tw-hidden');
      $('#success-snackbar').addClass('tw-hidden');
      window.toggleSnackbar('warning-snackbar', window.I18n.error);
    }
  },
});
export default DTAccountSubscriptionsView;
